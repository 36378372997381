/**
 * Created by rsimm on 1/30/2016.
 */
"use strict";

var NavBar = React.createClass({
   render: function(){
       return (
           <nav className="navbar navbar-inverse navbar-fixed-top">
               <div className="container-fluid">
                   <div className="navbar-header">
                       <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                           <span className="sr-only">Toggle navigation</span>
                           <span className="icon-bar" />
                           <span className="icon-bar" />
                           <span className="icon-bar" />
                       </button>
                       <a className="navbar-brand" href="#">TCS Consultants, Inc.</a>
                   </div>
                   <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                       <ul className="nav navbar-nav">
                           <li className="active"><a href="/">Home</a></li>
                           <li><a href="/microwave/link-site-and-path-survey-design.php">Path Surveys</a></li>
                           <li><a href="/coverage/rf-radio-propagation-prediction-analysis.php">Coverage Analysis</a></li>
                           <li><a href="/conversions/">Conversions</a></li>
                           <li><a href="/customer/">Login</a></li>
                       </ul>
                       <ul className="nav navbar-nav navbar-right">
                           <li className="dropdown">
                               <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Resources <span className="caret" /></a>
                               <ul className="dropdown-menu">
                                   <li><a href="http://maps.google.com" target="_blank">Google Maps</a></li>
                                   <li role="separator" className="divider" />
                                   <li><a href="http://wireless2.fcc.gov/UlsApp/UlsSearch/searchLicense.jsp" target="_blank">FCC Licenses</a></li>
                                   <li><a href="http://wireless2.fcc.gov/UlsApp/AsrSearch/towairSearch.jsp" target="_blank">FCC TowAIR</a></li>
                                   <li role="separator" className="divider" />
                                   <li><a href="/conversions">Conversions</a></li>
                               </ul>
                           </li>
                       </ul>
                   </div>
               </div>
           </nav>
       );
   }
});

ReactDOM.render(<NavBar />, document.getElementById('navigationContents'));